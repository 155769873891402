<template>
  <v-expansion-panels
    v-if="groups != undefined && groups.length"
    v-model="expanded"
  >
    <v-expansion-panel
      v-for="(group, index) in groups"
      :key="index"
      class="mb-4"
    >
      <v-expansion-panel-header
        hide-actions
        v-slot="{ open }"
        :style="headerStyles(index)"
        :class="headerClass(index)"
      >
        <v-row align="center">
          <v-col class="shrink">
            <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
            <v-icon v-else>mdi-plus-thick</v-icon>
          </v-col>
          <v-col :class="(open ? 'white--text' : '') + ' font-weight-medium'">
            {{ group.name }}
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-4">
        <NestedDocumentGroups
          :groups="group.groups"
          :selected="selected"
          @onSelecte="$emit('onSelecte', $event)"
        />

        <v-card v-if="group.documents.length">
          <v-data-table
            v-model="thisSelected"
            :headers="headers"
            :items="group.documents"
            disable-filtering
            disable-pagination
            hide-default-footer
            checkbox-color="msaBlue"
            item-key="id"
            show-select
            @item-selected="$emit('onSelecte', $event)"
            @toggle-select-all="$emit('onSelecte', $event)"
            class="available-document-list"
          >
          </v-data-table>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import NestedDocumentGroups from '@/components/WALabels/NestedDocumentGroups.vue';
export default {
  name: 'NestedDocumentGroups',
  components: {
    NestedDocumentGroups,
  },
  props: {
    groups: {
      type: Array,
    },
    selected: {
      type: Array,
    },
  },
  computed: {
    headerStyles() {
      return index =>
        index == this.expanded
          ? {}
          : {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
    },
    headerClass() {
      return index => (index == this.expanded ? 'msaBlue white--text' : '');
    },
  },
  data() {
    return {
      expanded: null,
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
      ],
      thisSelected: [],
    };
  },
  methods: {
    checkSelected() {
      if (this.groups != undefined) {
        this.groups.forEach(group => {
          this.thisSelected = this.thisSelected.concat(
            group.documents.filter(d => this.selected.some(s => s.id == d.id)),
          );
        });
      }
    },
  },
  beforeMount() {
    this.checkSelected();
  },
};
</script>

<style>
.available-document-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
