<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="openDialog()"
            data-testid="attach-documents"
          >
            <v-icon> mdi-tag-plus-outline </v-icon>
          </v-btn>
        </template>
        <span>Attach Documents</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row>
            <v-col class="text-h6"> Attach Documents </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <SearchBar
              :key="dialog"
              searchLabel="Search by document name..."
              @search="onSearch"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <NestedDocumentGroups
              :key="reload"
              :groups="items"
              :selected="selected"
              @onSelecte="onSelect($event)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-footer
        padless
        tile
        style="position: sticky; bottom: 0; z-index: 999"
        color="msaBlue"
      >
        <v-container fluid>
          <v-row>
            <v-col class="grow" align="right">
              <v-btn class="white red--text" @click="close()">cancel</v-btn>
            </v-col>
            <v-col class="shrink">
              <v-badge
                color="green"
                overlap
                :content="selected.length"
                :value="selected.length"
              >
                <v-btn
                  class="white msaBlue--text"
                  @click="confirm()"
                  :disabled="selected.length == 0"
                >
                  confirm
                </v-btn>
              </v-badge>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </v-card>
  </v-dialog>
</template>

<script>
import NestedDocumentGroups from '@/components/WALabels/NestedDocumentGroups.vue';
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'AttachDocuments',
  components: {
    SearchBar,
    NestedDocumentGroups,
  },
  data() {
    return {
      dialog: false,
      items: [],
      search: '',
      selected: [],
      reload: false,
    };
  },
  methods: {
    getItems() {
      const params = {
        search: this.search,
        loaderText: 'Loading...',
        labelId: this.$store.getters.selectedLabel.id,
      };

      this.$axios
        .post('get-available-private-documents-for-label?format=json', params)
        .then((response) => {
          this.items = response.data;
          this.reload = !this.reload;
        })
        .catch((error) => error);
    },
    onSearch(event) {
      this.search = event;
      this.getItems();
    },
    openDialog() {
      this.resetFields();
      this.getItems();
    },
    resetFields() {
      this.selected = [];
      this.items = [];
      this.search = '';
    },
    close() {
      this.dialog = false;
    },
    confirm() {
      const params = {
        documentIds: this.selected.map((s) => s.id),
        loaderText: 'Loading...',
        labelId: this.$store.getters.selectedLabel.id,
      };

      this.$axios
        .post('attach-documents-to-label?format=json', params)
        .then(() => {
          this.$emit('refresh');
          this.close();
        })
        .catch((error) => error);
    },
    onSelect(event) {
      if (event.value) {
        if (event.item != undefined) {
          this.selected.push(event.item);
        }

        if (event.currentItem != undefined) {
          this.selected.push(event.currentItem);
        }

        if (event.items != undefined) {
          this.selected = this.selected.concat(event.items);
        }
      } else {
        if (event.item != undefined) {
          const index = this.selected.findIndex((s) => s.id == event.item.id);
          this.selected.splice(index, 1);
        }

        if (event.currentItem != undefined) {
          const index = this.selected.findIndex(
            (s) => s.id == event.currentItem.id,
          );
          this.selected.splice(index, 1);
        }

        if (event.items != undefined) {
          this.selected = this.selected.filter(
            (s) => !event.items.some((i) => i.id == s.id),
          );
        }
      }
    },
  },
};
</script>

<style></style>
